'use client';

import { jsx } from 'react/jsx-runtime';
import { Fragment, createElement } from 'react';
import { useId } from '@mantine/hooks';
import { rem } from '../../../core/utils/units-converters/rem.mjs';
import { getFontSize } from '../../../core/utils/get-size/get-size.mjs';
import { createVarsResolver } from '../../../core/styles-api/create-vars-resolver/create-vars-resolver.mjs';
import 'clsx';
import '../../../core/MantineProvider/Mantine.context.mjs';
import '../../../core/MantineProvider/default-theme.mjs';
import '../../../core/MantineProvider/MantineProvider.mjs';
import '../../../core/MantineProvider/MantineThemeProvider/MantineThemeProvider.mjs';
import { useProps } from '../../../core/MantineProvider/use-props/use-props.mjs';
import '../../../core/MantineProvider/MantineCssVariables/MantineCssVariables.mjs';
import { useStyles } from '../../../core/styles-api/use-styles/use-styles.mjs';
import { Box } from '../../../core/Box/Box.mjs';
import { factory } from '../../../core/factory/factory.mjs';
import '../../../core/DirectionProvider/DirectionProvider.mjs';
import { InputDescription } from '../InputDescription/InputDescription.mjs';
import { InputError } from '../InputError/InputError.mjs';
import { InputLabel } from '../InputLabel/InputLabel.mjs';
import { InputWrapperProvider } from '../InputWrapper.context.mjs';
import { getInputOffsets } from './get-input-offsets/get-input-offsets.mjs';
import classes from '../Input.module.css.mjs';
const defaultProps = {
  labelElement: "label",
  inputContainer: children => children,
  inputWrapperOrder: ["label", "description", "input", "error"]
};
const varsResolver = createVarsResolver((_, {
  size
}) => ({
  label: {
    "--input-label-size": getFontSize(size),
    "--input-asterisk-color": void 0
  },
  error: {
    "--input-error-size": size === void 0 ? void 0 : `calc(${getFontSize(size)} - ${rem(2)})`
  },
  description: {
    "--input-description-size": size === void 0 ? void 0 : `calc(${getFontSize(size)} - ${rem(2)})`
  }
}));
const InputWrapper = factory((_props, ref) => {
  const props = useProps("InputWrapper", defaultProps, _props);
  const {
    classNames,
    className,
    style,
    styles,
    unstyled,
    vars,
    size,
    variant,
    __staticSelector,
    inputContainer,
    inputWrapperOrder,
    label,
    error,
    description,
    labelProps,
    descriptionProps,
    errorProps,
    labelElement,
    children,
    withAsterisk,
    id,
    required,
    __stylesApiProps,
    mod,
    ...others
  } = props;
  const getStyles = useStyles({
    name: ["InputWrapper", __staticSelector],
    props: __stylesApiProps || props,
    classes,
    className,
    style,
    classNames,
    styles,
    unstyled,
    vars,
    varsResolver
  });
  const sharedProps = {
    size,
    variant,
    __staticSelector
  };
  const idBase = useId(id);
  const isRequired = typeof withAsterisk === "boolean" ? withAsterisk : required;
  const errorId = errorProps?.id || `${idBase}-error`;
  const descriptionId = descriptionProps?.id || `${idBase}-description`;
  const inputId = idBase;
  const hasError = !!error && typeof error !== "boolean";
  const hasDescription = !!description;
  const _describedBy = `${hasError ? errorId : ""} ${hasDescription ? descriptionId : ""}`;
  const describedBy = _describedBy.trim().length > 0 ? _describedBy.trim() : void 0;
  const labelId = labelProps?.id || `${idBase}-label`;
  const _label = label && /* @__PURE__ */jsx(InputLabel, {
    labelElement,
    id: labelId,
    htmlFor: inputId,
    required: isRequired,
    ...sharedProps,
    ...labelProps,
    children: label
  }, "label");
  const _description = hasDescription && /* @__PURE__ */jsx(InputDescription, {
    ...descriptionProps,
    ...sharedProps,
    size: descriptionProps?.size || sharedProps.size,
    id: descriptionProps?.id || descriptionId,
    children: description
  }, "description");
  const _input = /* @__PURE__ */jsx(Fragment, {
    children: inputContainer(children)
  }, "input");
  const _error = hasError && /* @__PURE__ */createElement(InputError, {
    ...errorProps,
    ...sharedProps,
    size: errorProps?.size || sharedProps.size,
    key: "error",
    id: errorProps?.id || errorId
  }, error);
  const content = inputWrapperOrder.map(part => {
    switch (part) {
      case "label":
        return _label;
      case "input":
        return _input;
      case "description":
        return _description;
      case "error":
        return _error;
      default:
        return null;
    }
  });
  return /* @__PURE__ */jsx(InputWrapperProvider, {
    value: {
      getStyles,
      describedBy,
      inputId,
      labelId,
      ...getInputOffsets(inputWrapperOrder, {
        hasDescription,
        hasError
      })
    },
    children: /* @__PURE__ */jsx(Box, {
      ref,
      variant,
      size,
      mod: [{
        error: !!error
      }, mod],
      ...getStyles("root"),
      ...others,
      children: content
    })
  });
});
InputWrapper.classes = classes;
InputWrapper.displayName = "@mantine/core/InputWrapper";
export { InputWrapper };
