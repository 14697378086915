'use client';

function resolveStyle({
  style,
  theme
}) {
  if (Array.isArray(style)) {
    return [...style].reduce((acc, item) => ({
      ...acc,
      ...resolveStyle({
        style: item,
        theme
      })
    }), {});
  }
  if (typeof style === "function") {
    return style(theme);
  }
  if (style == null) {
    return {};
  }
  return style;
}
export { resolveStyle };
