'use client';

var classes = {
  "root": "m_89d60db1",
  "list--default": "m_576c9d4",
  "list": "m_89d33d6d",
  "panel": "m_b0c91715",
  "tab": "m_4ec4dce6",
  "tabSection": "m_fc420b1f",
  "tab--default": "m_539e827b",
  "list--outline": "m_6772fbd5",
  "tab--outline": "m_b59ab47c",
  "tab--pills": "m_c3381914"
};
export { classes as default };
