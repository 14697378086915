'use client';

import { useState } from 'react';
function useUncontrolled({
  value,
  defaultValue,
  finalValue,
  onChange = () => {}
}) {
  const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue !== void 0 ? defaultValue : finalValue);
  const handleUncontrolledChange = (val, ...payload) => {
    setUncontrolledValue(val);
    onChange?.(val, ...payload);
  };
  if (value !== void 0) {
    return [value, onChange, true];
  }
  return [uncontrolledValue, handleUncontrolledChange, false];
}
export { useUncontrolled };
