'use client';

import { jsx } from 'react/jsx-runtime';
import { useRef, useState, useCallback } from 'react';
import { useId, useClickOutside } from '@mantine/hooks';
import { getDefaultZIndex } from '../../core/utils/get-default-z-index/get-default-z-index.mjs';
import { getRadius, getShadow } from '../../core/utils/get-size/get-size.mjs';
import { createVarsResolver } from '../../core/styles-api/create-vars-resolver/create-vars-resolver.mjs';
import 'clsx';
import '../../core/MantineProvider/Mantine.context.mjs';
import '../../core/MantineProvider/default-theme.mjs';
import '../../core/MantineProvider/MantineProvider.mjs';
import '../../core/MantineProvider/MantineThemeProvider/MantineThemeProvider.mjs';
import { useProps } from '../../core/MantineProvider/use-props/use-props.mjs';
import '../../core/MantineProvider/MantineCssVariables/MantineCssVariables.mjs';
import { useStyles } from '../../core/styles-api/use-styles/use-styles.mjs';
import '../../core/Box/Box.mjs';
import { useDirection } from '../../core/DirectionProvider/DirectionProvider.mjs';
import '@floating-ui/react';
import { getFloatingPosition } from '../Floating/get-floating-position/get-floating-position.mjs';
import '../Floating/FloatingArrow/FloatingArrow.mjs';
import { PopoverContextProvider } from './Popover.context.mjs';
import { PopoverDropdown } from './PopoverDropdown/PopoverDropdown.mjs';
import { PopoverTarget } from './PopoverTarget/PopoverTarget.mjs';
import { usePopover } from './use-popover.mjs';
import classes from './Popover.module.css.mjs';
const defaultProps = {
  position: "bottom",
  offset: 8,
  positionDependencies: [],
  transitionProps: {
    transition: "fade",
    duration: 150
  },
  middlewares: {
    flip: true,
    shift: true,
    inline: false
  },
  arrowSize: 7,
  arrowOffset: 5,
  arrowRadius: 0,
  arrowPosition: "side",
  closeOnClickOutside: true,
  withinPortal: true,
  closeOnEscape: true,
  trapFocus: false,
  withRoles: true,
  returnFocus: false,
  clickOutsideEvents: ["mousedown", "touchstart"],
  zIndex: getDefaultZIndex("popover"),
  __staticSelector: "Popover",
  width: "max-content"
};
const varsResolver = createVarsResolver((_, {
  radius,
  shadow
}) => ({
  dropdown: {
    "--popover-radius": radius === void 0 ? void 0 : getRadius(radius),
    "--popover-shadow": getShadow(shadow)
  }
}));
function Popover(_props) {
  const props = useProps("Popover", defaultProps, _props);
  const {
    children,
    position,
    offset,
    onPositionChange,
    positionDependencies,
    opened,
    transitionProps,
    width,
    middlewares,
    withArrow,
    arrowSize,
    arrowOffset,
    arrowRadius,
    arrowPosition,
    unstyled,
    classNames,
    styles,
    closeOnClickOutside,
    withinPortal,
    portalProps,
    closeOnEscape,
    clickOutsideEvents,
    trapFocus,
    onClose,
    onOpen,
    onChange,
    zIndex,
    radius,
    shadow,
    id,
    defaultOpened,
    __staticSelector,
    withRoles,
    disabled,
    returnFocus,
    variant,
    keepMounted,
    vars,
    floatingStrategy,
    ...others
  } = props;
  const getStyles = useStyles({
    name: __staticSelector,
    props,
    classes,
    classNames,
    styles,
    unstyled,
    rootSelector: "dropdown",
    vars,
    varsResolver
  });
  const arrowRef = useRef(null);
  const [targetNode, setTargetNode] = useState(null);
  const [dropdownNode, setDropdownNode] = useState(null);
  const {
    dir
  } = useDirection();
  const uid = useId(id);
  const popover = usePopover({
    middlewares,
    width,
    position: getFloatingPosition(dir, position),
    offset: typeof offset === "number" ? offset + (withArrow ? arrowSize / 2 : 0) : offset,
    arrowRef,
    arrowOffset,
    onPositionChange,
    positionDependencies,
    opened,
    defaultOpened,
    onChange,
    onOpen,
    onClose,
    strategy: floatingStrategy
  });
  useClickOutside(() => closeOnClickOutside && popover.onClose(), clickOutsideEvents, [targetNode, dropdownNode]);
  const reference = useCallback(node => {
    setTargetNode(node);
    popover.floating.refs.setReference(node);
  }, [popover.floating.refs.setReference]);
  const floating = useCallback(node => {
    setDropdownNode(node);
    popover.floating.refs.setFloating(node);
  }, [popover.floating.refs.setFloating]);
  return /* @__PURE__ */jsx(PopoverContextProvider, {
    value: {
      returnFocus,
      disabled,
      controlled: popover.controlled,
      reference,
      floating,
      x: popover.floating.x,
      y: popover.floating.y,
      arrowX: popover.floating?.middlewareData?.arrow?.x,
      arrowY: popover.floating?.middlewareData?.arrow?.y,
      opened: popover.opened,
      arrowRef,
      transitionProps,
      width,
      withArrow,
      arrowSize,
      arrowOffset,
      arrowRadius,
      arrowPosition,
      placement: popover.floating.placement,
      trapFocus,
      withinPortal,
      portalProps,
      zIndex,
      radius,
      shadow,
      closeOnEscape,
      onClose: popover.onClose,
      onToggle: popover.onToggle,
      getTargetId: () => `${uid}-target`,
      getDropdownId: () => `${uid}-dropdown`,
      withRoles,
      targetProps: others,
      __staticSelector,
      classNames,
      styles,
      unstyled,
      variant,
      keepMounted,
      getStyles,
      floatingStrategy
    },
    children
  });
}
Popover.Target = PopoverTarget;
Popover.Dropdown = PopoverDropdown;
Popover.displayName = "@mantine/core/Popover";
Popover.extend = input => input;
export { Popover };
