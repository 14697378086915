import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.sass']
})
export class AuthenticationComponent implements OnInit, OnDestroy {

  constructor(@Inject(DOCUMENT) private document: Document,
              private modalService: NgbModal) {
    this.document.body.classList.add('bg-body');
    modalService.dismissAll();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('bg-body');
  }
}
