'use client';

import { isMantineColorScheme } from './is-mantine-color-scheme.mjs';
function localStorageColorSchemeManager({
  key = "mantine-color-scheme-value"
} = {}) {
  let handleStorageEvent;
  return {
    get: defaultValue => {
      if (typeof window === "undefined") {
        return defaultValue;
      }
      try {
        const storedColorScheme = window.localStorage.getItem(key);
        return isMantineColorScheme(storedColorScheme) ? storedColorScheme : defaultValue;
      } catch {
        return defaultValue;
      }
    },
    set: value => {
      try {
        window.localStorage.setItem(key, value);
      } catch (error) {
        console.warn("[@mantine/core] Local storage color scheme manager was unable to save color scheme.", error);
      }
    },
    subscribe: onUpdate => {
      handleStorageEvent = event => {
        if (event.storageArea === window.localStorage && event.key === key) {
          isMantineColorScheme(event.newValue) && onUpdate(event.newValue);
        }
      };
      window.addEventListener("storage", handleStorageEvent);
    },
    unsubscribe: () => {
      window.removeEventListener("storage", handleStorageEvent);
    },
    clear: () => {
      window.localStorage.removeItem(key);
    }
  };
}
export { localStorageColorSchemeManager };
