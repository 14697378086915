'use client';

var classes = {
  "root": "m_77c9d27d",
  "inner": "m_80f1301b",
  "label": "m_811560b9",
  "section": "m_a74036a",
  "loader": "m_a25b86ee",
  "group": "m_80d6d844"
};
export { classes as default };
