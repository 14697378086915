'use client';

import { jsx } from 'react/jsx-runtime';
import 'react';
import '@mantine/hooks';
import 'clsx';
import '../../core/MantineProvider/Mantine.context.mjs';
import '../../core/MantineProvider/default-theme.mjs';
import '../../core/MantineProvider/MantineProvider.mjs';
import '../../core/MantineProvider/MantineThemeProvider/MantineThemeProvider.mjs';
import { useProps } from '../../core/MantineProvider/use-props/use-props.mjs';
import '../../core/MantineProvider/MantineCssVariables/MantineCssVariables.mjs';
import '../../core/Box/Box.mjs';
import { factory } from '../../core/factory/factory.mjs';
import '../../core/DirectionProvider/DirectionProvider.mjs';
import { InputBase } from '../InputBase/InputBase.mjs';
const defaultProps = {};
const TextInput = factory((props, ref) => {
  const _props = useProps("TextInput", defaultProps, props);
  return /* @__PURE__ */jsx(InputBase, {
    component: "input",
    ref,
    ..._props,
    __staticSelector: "TextInput"
  });
});
TextInput.classes = InputBase.classes;
TextInput.displayName = "@mantine/core/TextInput";
export { TextInput };
