import {Component, inject, OnInit} from '@angular/core';
import { fromEvent, Observable } from "rxjs";
import { environment } from "../environments/environment";
import packageInfo from "../../package.json";
import { TranslateService } from '@ngx-translate/core';
import { BasicsRepository } from './data/repository/basics.repository';
import { Messaging } from '@angular/fire/messaging';
import {NotificationService} from "./share/services/notification.service";
import {UrlUtil} from "./share/utils/url.util";
import { ThemeService } from './share/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})

export class AppComponent implements OnInit {

  title = 'panel';
  offlineEvent: Observable<Event>;
  onlineEvent: Observable<Event>;
  isOnline: boolean = true;
  devMode: boolean = true;
  version = packageInfo.version;


  constructor(private translate: TranslateService,
              private notificationService:NotificationService,
              private urlUtil:UrlUtil,
              private themeService:ThemeService,
              private baseRepo: BasicsRepository) {
    localStorage.setItem('domain', this.urlUtil.getDomain());

    let lang = baseRepo.getLanguage();
    if (lang == null)
      lang = 'en';

    baseRepo.switchLang(lang);
  }

  ngOnInit(): void {
    this.devMode = environment.production;
    this.handleAppConnectivityChanges();
    this.themeService.setTheme(this.baseRepo.getTheme());
  }


  private handleAppConnectivityChanges(): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.onlineEvent.subscribe(event => {
      this.isOnline = true;
    });

    this.offlineEvent.subscribe(event => {
      this.isOnline = false;
    });
  }
}
