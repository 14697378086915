'use client';

import { jsx, Fragment } from 'react/jsx-runtime';
import { getTransitionStyles } from './get-transition-styles/get-transition-styles.mjs';
import { useTransition } from './use-transition.mjs';
function Transition({
  keepMounted,
  transition = "fade",
  duration = 250,
  exitDuration = duration,
  mounted,
  children,
  timingFunction = "ease",
  onExit,
  onEntered,
  onEnter,
  onExited,
  enterDelay,
  exitDelay
}) {
  const {
    transitionDuration,
    transitionStatus,
    transitionTimingFunction
  } = useTransition({
    mounted,
    exitDuration,
    duration,
    timingFunction,
    onExit,
    onEntered,
    onEnter,
    onExited,
    enterDelay,
    exitDelay
  });
  if (transitionDuration === 0) {
    return mounted ? /* @__PURE__ */jsx(Fragment, {
      children: children({})
    }) : keepMounted ? children({
      display: "none"
    }) : null;
  }
  return transitionStatus === "exited" ? keepMounted ? children({
    display: "none"
  }) : null : /* @__PURE__ */jsx(Fragment, {
    children: children(getTransitionStyles({
      transition,
      duration: transitionDuration,
      state: transitionStatus,
      timingFunction: transitionTimingFunction
    }))
  });
}
Transition.displayName = "@mantine/core/Transition";
export { Transition };
