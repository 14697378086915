'use client';

function getStaticClassNames({
  themeName,
  classNamesPrefix,
  selector,
  withStaticClass
}) {
  if (withStaticClass === false) {
    return [];
  }
  return themeName.map(n => `${classNamesPrefix}-${n}-${selector}`);
}
export { getStaticClassNames };
