'use client';

import { jsx } from 'react/jsx-runtime';
import { FloatingDelayGroup } from '@floating-ui/react';
import 'react';
import '@mantine/hooks';
import 'clsx';
import '../../../core/MantineProvider/Mantine.context.mjs';
import '../../../core/MantineProvider/default-theme.mjs';
import '../../../core/MantineProvider/MantineProvider.mjs';
import '../../../core/MantineProvider/MantineThemeProvider/MantineThemeProvider.mjs';
import { useProps } from '../../../core/MantineProvider/use-props/use-props.mjs';
import '../../../core/MantineProvider/MantineCssVariables/MantineCssVariables.mjs';
import '../../../core/Box/Box.mjs';
import '../../../core/DirectionProvider/DirectionProvider.mjs';
import { TooltipGroupProvider } from './TooltipGroup.context.mjs';
const defaultProps = {
  openDelay: 0,
  closeDelay: 0
};
function TooltipGroup(props) {
  const {
    openDelay,
    closeDelay,
    children
  } = useProps("TooltipGroup", defaultProps, props);
  return /* @__PURE__ */jsx(TooltipGroupProvider, {
    value: true,
    children: /* @__PURE__ */jsx(FloatingDelayGroup, {
      delay: {
        open: openDelay,
        close: closeDelay
      },
      children
    })
  });
}
TooltipGroup.displayName = "@mantine/core/TooltipGroup";
TooltipGroup.extend = c => c;
export { TooltipGroup };
