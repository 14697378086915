'use client';

function transformModKey(key) {
  return key.startsWith("data-") ? key : `data-${key}`;
}
function getMod(props) {
  return Object.keys(props).reduce((acc, key) => {
    const value = props[key];
    if (value === void 0 || value === "" || value === false || value === null) {
      return acc;
    }
    acc[transformModKey(key)] = props[key];
    return acc;
  }, {});
}
function getBoxMod(mod) {
  if (!mod) {
    return null;
  }
  if (typeof mod === "string") {
    return {
      [transformModKey(mod)]: true
    };
  }
  if (Array.isArray(mod)) {
    return [...mod].reduce((acc, value) => ({
      ...acc,
      ...getBoxMod(value)
    }), {});
  }
  return getMod(mod);
}
export { getBoxMod, getMod };
