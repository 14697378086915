'use client';

var classes = {
  "wrapper": "m_6c018570",
  "input": "m_8fb7ebe7",
  "section": "m_82577fc2",
  "placeholder": "m_88bacfd0",
  "root": "m_46b77525",
  "label": "m_8fdc1311",
  "required": "m_78a94662",
  "error": "m_8f816625",
  "description": "m_fe47ce59"
};
export { classes as default };
