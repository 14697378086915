'use client';

import { keys } from '../../../utils/keys/keys.mjs';
import 'react';
import 'react/jsx-runtime';
import '@mantine/hooks';
import { resolvers } from '../resolvers/index.mjs';
import { sortMediaQueries } from './sort-media-queries.mjs';
function hasResponsiveStyles(styleProp) {
  if (typeof styleProp !== "object" || styleProp === null) {
    return false;
  }
  const breakpoints = Object.keys(styleProp);
  if (breakpoints.length === 1 && breakpoints[0] === "base") {
    return false;
  }
  return true;
}
function getBaseValue(value) {
  if (typeof value === "object" && value !== null) {
    if ("base" in value) {
      return value.base;
    }
    return void 0;
  }
  return value;
}
function getBreakpointKeys(value) {
  if (typeof value === "object" && value !== null) {
    return keys(value).filter(key => key !== "base");
  }
  return [];
}
function getBreakpointValue(value, breakpoint) {
  if (typeof value === "object" && value !== null && breakpoint in value) {
    return value[breakpoint];
  }
  return value;
}
function parseStyleProps({
  styleProps,
  data,
  theme
}) {
  return sortMediaQueries(keys(styleProps).reduce((acc, styleProp) => {
    if (styleProp === "hiddenFrom" || styleProp === "visibleFrom" || styleProp === "sx") {
      return acc;
    }
    const propertyData = data[styleProp];
    const properties = Array.isArray(propertyData.property) ? propertyData.property : [propertyData.property];
    const baseValue = getBaseValue(styleProps[styleProp]);
    if (!hasResponsiveStyles(styleProps[styleProp])) {
      properties.forEach(property => {
        acc.inlineStyles[property] = resolvers[propertyData.type](baseValue, theme);
      });
      return acc;
    }
    acc.hasResponsiveStyles = true;
    const breakpoints = getBreakpointKeys(styleProps[styleProp]);
    properties.forEach(property => {
      if (baseValue) {
        acc.styles[property] = resolvers[propertyData.type](baseValue, theme);
      }
      breakpoints.forEach(breakpoint => {
        const bp = `(min-width: ${theme.breakpoints[breakpoint]})`;
        acc.media[bp] = {
          ...acc.media[bp],
          [property]: resolvers[propertyData.type](getBreakpointValue(styleProps[styleProp], breakpoint), theme)
        };
      });
    });
    return acc;
  }, {
    hasResponsiveStyles: false,
    styles: {},
    inlineStyles: {},
    media: {}
  }));
}
export { parseStyleProps };
