'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import 'react';
import { getSize, getRadius } from '../../core/utils/get-size/get-size.mjs';
import '@mantine/hooks';
import { createVarsResolver } from '../../core/styles-api/create-vars-resolver/create-vars-resolver.mjs';
import 'clsx';
import { getThemeColor } from '../../core/MantineProvider/color-functions/get-theme-color/get-theme-color.mjs';
import '../../core/MantineProvider/Mantine.context.mjs';
import '../../core/MantineProvider/default-theme.mjs';
import '../../core/MantineProvider/MantineProvider.mjs';
import '../../core/MantineProvider/MantineThemeProvider/MantineThemeProvider.mjs';
import { useProps } from '../../core/MantineProvider/use-props/use-props.mjs';
import '../../core/MantineProvider/MantineCssVariables/MantineCssVariables.mjs';
import { useStyles } from '../../core/styles-api/use-styles/use-styles.mjs';
import { Box } from '../../core/Box/Box.mjs';
import { polymorphicFactory } from '../../core/factory/polymorphic-factory.mjs';
import '../../core/DirectionProvider/DirectionProvider.mjs';
import classes from './Badge.module.css.mjs';
const defaultProps = {};
const varsResolver = createVarsResolver((theme, {
  radius,
  color,
  gradient,
  variant,
  size,
  autoContrast
}) => {
  const colors = theme.variantColorResolver({
    color: color || theme.primaryColor,
    theme,
    gradient,
    variant: variant || "filled",
    autoContrast
  });
  return {
    root: {
      "--badge-height": getSize(size, "badge-height"),
      "--badge-padding-x": getSize(size, "badge-padding-x"),
      "--badge-fz": getSize(size, "badge-fz"),
      "--badge-radius": radius === void 0 ? void 0 : getRadius(radius),
      "--badge-bg": color || variant ? colors.background : void 0,
      "--badge-color": color || variant ? colors.color : void 0,
      "--badge-bd": color || variant ? colors.border : void 0,
      "--badge-dot-color": variant === "dot" ? getThemeColor(color, theme) : void 0
    }
  };
});
const Badge = polymorphicFactory((_props, ref) => {
  const props = useProps("Badge", defaultProps, _props);
  const {
    classNames,
    className,
    style,
    styles,
    unstyled,
    vars,
    radius,
    color,
    gradient,
    leftSection,
    rightSection,
    children,
    variant,
    fullWidth,
    autoContrast,
    circle,
    mod,
    ...others
  } = props;
  const getStyles = useStyles({
    name: "Badge",
    props,
    classes,
    className,
    style,
    classNames,
    styles,
    unstyled,
    vars,
    varsResolver
  });
  return /* @__PURE__ */jsxs(Box, {
    variant,
    mod: [{
      block: fullWidth,
      circle,
      "with-right-section": !!rightSection,
      "with-left-section": !!leftSection
    }, mod],
    ...getStyles("root", {
      variant
    }),
    ref,
    ...others,
    children: [leftSection && /* @__PURE__ */jsx("span", {
      ...getStyles("section"),
      "data-position": "left",
      children: leftSection
    }), /* @__PURE__ */jsx("span", {
      ...getStyles("label"),
      children
    }), rightSection && /* @__PURE__ */jsx("span", {
      ...getStyles("section"),
      "data-position": "right",
      children: rightSection
    })]
  });
});
Badge.classes = classes;
Badge.displayName = "@mantine/core/Badge";
export { Badge };
