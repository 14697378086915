'use client';

import { jsx, Fragment } from 'react/jsx-runtime';
import { Portal } from './Portal.mjs';
function OptionalPortal({
  withinPortal = true,
  children,
  ...others
}) {
  if (withinPortal) {
    return /* @__PURE__ */jsx(Portal, {
      ...others,
      children
    });
  }
  return /* @__PURE__ */jsx(Fragment, {
    children
  });
}
OptionalPortal.displayName = "@mantine/core/OptionalPortal";
export { OptionalPortal };
