'use client';

var classes = {
  "dropdown": "m_dc9b7c9f",
  "label": "m_9bfac126",
  "divider": "m_efdf90cb",
  "item": "m_99ac2aa1",
  "itemLabel": "m_5476e0d3",
  "itemSection": "m_8b75e504"
};
export { classes as default };
