'use client';

import { jsx } from 'react/jsx-runtime';
import { rem } from '../../core/utils/units-converters/rem.mjs';
import 'react';
import { getDefaultZIndex } from '../../core/utils/get-default-z-index/get-default-z-index.mjs';
import { getRadius } from '../../core/utils/get-size/get-size.mjs';
import '@mantine/hooks';
import { createVarsResolver } from '../../core/styles-api/create-vars-resolver/create-vars-resolver.mjs';
import 'clsx';
import { rgba } from '../../core/MantineProvider/color-functions/rgba/rgba.mjs';
import '../../core/MantineProvider/Mantine.context.mjs';
import '../../core/MantineProvider/default-theme.mjs';
import '../../core/MantineProvider/MantineProvider.mjs';
import '../../core/MantineProvider/MantineThemeProvider/MantineThemeProvider.mjs';
import { useProps } from '../../core/MantineProvider/use-props/use-props.mjs';
import '../../core/MantineProvider/MantineCssVariables/MantineCssVariables.mjs';
import { useStyles } from '../../core/styles-api/use-styles/use-styles.mjs';
import { Box } from '../../core/Box/Box.mjs';
import { polymorphicFactory } from '../../core/factory/polymorphic-factory.mjs';
import '../../core/DirectionProvider/DirectionProvider.mjs';
import classes from './Overlay.module.css.mjs';
const defaultProps = {
  zIndex: getDefaultZIndex("modal")
};
const varsResolver = createVarsResolver((_, {
  gradient,
  color,
  backgroundOpacity,
  blur,
  radius,
  zIndex
}) => ({
  root: {
    "--overlay-bg": gradient || (color !== void 0 || backgroundOpacity !== void 0) && rgba(color || "#000", backgroundOpacity ?? 0.6) || void 0,
    "--overlay-filter": blur ? `blur(${rem(blur)})` : void 0,
    "--overlay-radius": radius === void 0 ? void 0 : getRadius(radius),
    "--overlay-z-index": zIndex?.toString()
  }
}));
const Overlay = polymorphicFactory((_props, ref) => {
  const props = useProps("Overlay", defaultProps, _props);
  const {
    classNames,
    className,
    style,
    styles,
    unstyled,
    vars,
    fixed,
    center,
    children,
    radius,
    zIndex,
    gradient,
    blur,
    color,
    backgroundOpacity,
    mod,
    ...others
  } = props;
  const getStyles = useStyles({
    name: "Overlay",
    props,
    classes,
    className,
    style,
    classNames,
    styles,
    unstyled,
    vars,
    varsResolver
  });
  return /* @__PURE__ */jsx(Box, {
    ref,
    ...getStyles("root"),
    mod: [{
      center,
      fixed
    }, mod],
    ...others,
    children
  });
});
Overlay.classes = classes;
Overlay.displayName = "@mantine/core/Overlay";
export { Overlay };
