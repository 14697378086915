'use client';

function getStyleObject(style, theme) {
  if (Array.isArray(style)) {
    return [...style].reduce((acc, item) => ({
      ...acc,
      ...getStyleObject(item, theme)
    }), {});
  }
  if (typeof style === "function") {
    return style(theme);
  }
  if (style == null) {
    return {};
  }
  return style;
}
export { getStyleObject };
