'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { rem } from '../../core/utils/units-converters/rem.mjs';
import 'react';
import { getSize, getFontSize, getRadius } from '../../core/utils/get-size/get-size.mjs';
import '@mantine/hooks';
import { createVarsResolver } from '../../core/styles-api/create-vars-resolver/create-vars-resolver.mjs';
import 'clsx';
import '../../core/MantineProvider/Mantine.context.mjs';
import '../../core/MantineProvider/default-theme.mjs';
import '../../core/MantineProvider/MantineProvider.mjs';
import '../../core/MantineProvider/MantineThemeProvider/MantineThemeProvider.mjs';
import { useProps } from '../../core/MantineProvider/use-props/use-props.mjs';
import '../../core/MantineProvider/MantineCssVariables/MantineCssVariables.mjs';
import { useStyles } from '../../core/styles-api/use-styles/use-styles.mjs';
import { extractStyleProps } from '../../core/Box/style-props/extract-style-props/extract-style-props.mjs';
import { Box } from '../../core/Box/Box.mjs';
import { polymorphicFactory } from '../../core/factory/polymorphic-factory.mjs';
import '../../core/DirectionProvider/DirectionProvider.mjs';
import { InputDescription } from './InputDescription/InputDescription.mjs';
import { InputError } from './InputError/InputError.mjs';
import { InputLabel } from './InputLabel/InputLabel.mjs';
import { InputPlaceholder } from './InputPlaceholder/InputPlaceholder.mjs';
import { useInputWrapperContext } from './InputWrapper.context.mjs';
import { InputWrapper } from './InputWrapper/InputWrapper.mjs';
import classes from './Input.module.css.mjs';
const defaultProps = {
  variant: "default",
  leftSectionPointerEvents: "none",
  rightSectionPointerEvents: "none",
  withAria: true,
  withErrorStyles: true
};
const varsResolver = createVarsResolver((_, props, ctx) => ({
  wrapper: {
    "--input-margin-top": ctx.offsetTop ? "calc(var(--mantine-spacing-xs) / 2)" : void 0,
    "--input-margin-bottom": ctx.offsetBottom ? "calc(var(--mantine-spacing-xs) / 2)" : void 0,
    "--input-height": getSize(props.size, "input-height"),
    "--input-fz": getFontSize(props.size),
    "--input-radius": props.radius === void 0 ? void 0 : getRadius(props.radius),
    "--input-left-section-width": props.leftSectionWidth !== void 0 ? rem(props.leftSectionWidth) : void 0,
    "--input-right-section-width": props.rightSectionWidth !== void 0 ? rem(props.rightSectionWidth) : void 0,
    "--input-padding-y": props.multiline ? getSize(props.size, "input-padding-y") : void 0,
    "--input-left-section-pointer-events": props.leftSectionPointerEvents,
    "--input-right-section-pointer-events": props.rightSectionPointerEvents
  }
}));
const Input = polymorphicFactory((_props, ref) => {
  const props = useProps("Input", defaultProps, _props);
  const {
    classNames,
    className,
    style,
    styles,
    unstyled,
    required,
    __staticSelector,
    __stylesApiProps,
    size,
    wrapperProps,
    error,
    disabled,
    leftSection,
    leftSectionProps,
    leftSectionWidth,
    rightSection,
    rightSectionProps,
    rightSectionWidth,
    rightSectionPointerEvents,
    leftSectionPointerEvents,
    variant,
    vars,
    pointer,
    multiline,
    radius,
    id,
    withAria,
    withErrorStyles,
    mod,
    inputSize,
    ...others
  } = props;
  const {
    styleProps,
    rest
  } = extractStyleProps(others);
  const ctx = useInputWrapperContext();
  const stylesCtx = {
    offsetBottom: ctx?.offsetBottom,
    offsetTop: ctx?.offsetTop
  };
  const getStyles = useStyles({
    name: ["Input", __staticSelector],
    props: __stylesApiProps || props,
    classes,
    className,
    style,
    classNames,
    styles,
    unstyled,
    stylesCtx,
    rootSelector: "wrapper",
    vars,
    varsResolver
  });
  const ariaAttributes = withAria ? {
    required,
    disabled,
    "aria-invalid": !!error,
    "aria-describedby": ctx?.describedBy,
    id: ctx?.inputId || id
  } : {};
  return /* @__PURE__ */jsxs(Box, {
    ...getStyles("wrapper"),
    ...styleProps,
    ...wrapperProps,
    mod: [{
      error: !!error && withErrorStyles,
      pointer,
      disabled,
      multiline,
      "data-with-right-section": !!rightSection,
      "data-with-left-section": !!leftSection
    }, mod],
    variant,
    size,
    children: [leftSection && /* @__PURE__ */jsx("div", {
      ...leftSectionProps,
      "data-position": "left",
      ...getStyles("section", {
        className: leftSectionProps?.className,
        style: leftSectionProps?.style
      }),
      children: leftSection
    }), /* @__PURE__ */jsx(Box, {
      component: "input",
      ...rest,
      ...ariaAttributes,
      ref,
      required,
      mod: {
        disabled,
        error: !!error && withErrorStyles
      },
      variant,
      __size: inputSize,
      ...getStyles("input")
    }), rightSection && /* @__PURE__ */jsx("div", {
      ...rightSectionProps,
      "data-position": "right",
      ...getStyles("section", {
        className: rightSectionProps?.className,
        style: rightSectionProps?.style
      }),
      children: rightSection
    })]
  });
});
Input.classes = classes;
Input.Wrapper = InputWrapper;
Input.Label = InputLabel;
Input.Error = InputError;
Input.Description = InputDescription;
Input.Placeholder = InputPlaceholder;
Input.displayName = "@mantine/core/Input";
export { Input };
