'use client';

import { jsx } from 'react/jsx-runtime';
import 'react';
import '@mantine/hooks';
import 'clsx';
import '../../core/MantineProvider/Mantine.context.mjs';
import '../../core/MantineProvider/default-theme.mjs';
import '../../core/MantineProvider/MantineProvider.mjs';
import '../../core/MantineProvider/MantineThemeProvider/MantineThemeProvider.mjs';
import { useProps } from '../../core/MantineProvider/use-props/use-props.mjs';
import '../../core/MantineProvider/MantineCssVariables/MantineCssVariables.mjs';
import { useStyles } from '../../core/styles-api/use-styles/use-styles.mjs';
import { Box } from '../../core/Box/Box.mjs';
import { polymorphicFactory } from '../../core/factory/polymorphic-factory.mjs';
import '../../core/DirectionProvider/DirectionProvider.mjs';
import classes from './UnstyledButton.module.css.mjs';
const defaultProps = {
  __staticSelector: "UnstyledButton"
};
const UnstyledButton = polymorphicFactory((_props, ref) => {
  const props = useProps("UnstyledButton", defaultProps, _props);
  const {
    className,
    component = "button",
    __staticSelector,
    unstyled,
    classNames,
    styles,
    style,
    ...others
  } = props;
  const getStyles = useStyles({
    name: __staticSelector,
    props,
    classes,
    className,
    style,
    classNames,
    styles,
    unstyled
  });
  return /* @__PURE__ */jsx(Box, {
    ...getStyles("root", {
      focusable: true
    }),
    component,
    ref,
    type: component === "button" ? "button" : void 0,
    ...others
  });
});
UnstyledButton.classes = classes;
UnstyledButton.displayName = "@mantine/core/UnstyledButton";
export { UnstyledButton };
