'use client';

import { jsx } from 'react/jsx-runtime';
import { useId, useUncontrolled } from '@mantine/hooks';
import 'react';
import { getSafeId } from '../../core/utils/get-safe-id/get-safe-id.mjs';
import { getRadius } from '../../core/utils/get-size/get-size.mjs';
import { createVarsResolver } from '../../core/styles-api/create-vars-resolver/create-vars-resolver.mjs';
import 'clsx';
import { getThemeColor } from '../../core/MantineProvider/color-functions/get-theme-color/get-theme-color.mjs';
import { getContrastColor } from '../../core/MantineProvider/color-functions/get-contrast-color/get-contrast-color.mjs';
import { getAutoContrastValue } from '../../core/MantineProvider/color-functions/get-auto-contrast-value/get-auto-contrast-value.mjs';
import '../../core/MantineProvider/Mantine.context.mjs';
import '../../core/MantineProvider/default-theme.mjs';
import '../../core/MantineProvider/MantineProvider.mjs';
import '../../core/MantineProvider/MantineThemeProvider/MantineThemeProvider.mjs';
import { useProps } from '../../core/MantineProvider/use-props/use-props.mjs';
import '../../core/MantineProvider/MantineCssVariables/MantineCssVariables.mjs';
import { useStyles } from '../../core/styles-api/use-styles/use-styles.mjs';
import { Box } from '../../core/Box/Box.mjs';
import { factory } from '../../core/factory/factory.mjs';
import '../../core/DirectionProvider/DirectionProvider.mjs';
import { TabsProvider } from './Tabs.context.mjs';
import { TabsList } from './TabsList/TabsList.mjs';
import { TabsPanel } from './TabsPanel/TabsPanel.mjs';
import { TabsTab } from './TabsTab/TabsTab.mjs';
import classes from './Tabs.module.css.mjs';
const VALUE_ERROR = "Tabs.Tab or Tabs.Panel component was rendered with invalid value or without value";
const defaultProps = {
  keepMounted: true,
  orientation: "horizontal",
  loop: true,
  activateTabWithKeyboard: true,
  allowTabDeactivation: false,
  unstyled: false,
  inverted: false,
  variant: "default",
  placement: "left"
};
const varsResolver = createVarsResolver((theme, {
  radius,
  color,
  autoContrast
}) => ({
  root: {
    "--tabs-radius": getRadius(radius),
    "--tabs-color": getThemeColor(color, theme),
    "--tabs-text-color": getAutoContrastValue(autoContrast, theme) ? getContrastColor({
      color,
      theme,
      autoContrast
    }) : void 0
  }
}));
const Tabs = factory((_props, ref) => {
  const props = useProps("Tabs", defaultProps, _props);
  const {
    defaultValue,
    value,
    onChange,
    orientation,
    children,
    loop,
    id,
    activateTabWithKeyboard,
    allowTabDeactivation,
    variant,
    color,
    radius,
    inverted,
    placement,
    keepMounted,
    classNames,
    styles,
    unstyled,
    className,
    style,
    vars,
    autoContrast,
    mod,
    ...others
  } = props;
  const uid = useId(id);
  const [currentTab, setCurrentTab] = useUncontrolled({
    value,
    defaultValue,
    finalValue: null,
    onChange
  });
  const getStyles = useStyles({
    name: "Tabs",
    props,
    classes,
    className,
    style,
    classNames,
    styles,
    unstyled,
    vars,
    varsResolver
  });
  return /* @__PURE__ */jsx(TabsProvider, {
    value: {
      placement,
      value: currentTab,
      orientation,
      id: uid,
      loop,
      activateTabWithKeyboard,
      getTabId: getSafeId(`${uid}-tab`, VALUE_ERROR),
      getPanelId: getSafeId(`${uid}-panel`, VALUE_ERROR),
      onChange: setCurrentTab,
      allowTabDeactivation,
      variant,
      color,
      radius,
      inverted,
      keepMounted,
      unstyled,
      getStyles
    },
    children: /* @__PURE__ */jsx(Box, {
      ref,
      id: uid,
      variant,
      mod: [{
        orientation,
        inverted: orientation === "horizontal" && inverted,
        placement: orientation === "vertical" && placement
      }, mod],
      ...getStyles("root"),
      ...others,
      children
    })
  });
});
Tabs.classes = classes;
Tabs.displayName = "@mantine/core/Tabs";
Tabs.Tab = TabsTab;
Tabs.Panel = TabsPanel;
Tabs.List = TabsList;
export { Tabs };
