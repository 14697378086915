'use client';

function getRootClassName({
  rootSelector,
  selector,
  className
}) {
  return rootSelector === selector ? className : void 0;
}
export { getRootClassName };
