import {Injectable} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {delay, forkJoin, mergeAll, Observable, switchMap, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {ApiService} from "../api/api.service";
import {ToastrService} from "ngx-toastr";
import {environment} from "../../../../environments/environment";
import {RxjsService} from "../rxjs/rxjs.service";
import {SheetRepository} from "../../../data/repository/sheet.repository";

@Injectable()
export class PanelInterceptor implements HttpInterceptor {
  matterMostProvider = environment.matterMostProvider;
  requests: any[] = [];
  flag: boolean = true;
  constructor(private router: Router,
              private api: ApiService,
              private rxjs: RxjsService,
              private _toastr: ToastrService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request.url;
    const token = url.includes(this.matterMostProvider) ? localStorage.getItem('TMM') : localStorage.getItem('token');
    const req = this.addTokenHeader(request, token);
    return next.handle(req).pipe(
      catchError(error => {
        if (error.error.statusCode == 401) {
          this.requests.push(request);
          if (this.flag) {
            this.flag = false;
            return this.api.refreshToken().pipe(
              delay(500),
              switchMap((response: any) => {
                this.flag = true;
                localStorage.setItem('token', response.data.accessToken);
                localStorage.setItem('refreshToken', response.data.refreshToken);

                // Map each request to an observable and collect them in an array
                const observables: Observable<HttpEvent<any>>[] = this.requests.map(req =>
                  next.handle(this.addTokenHeader(req, response.data.accessToken))
                );

                // this.rxjs.broadcastLottiLoading(true);
                if (this.requests.length > 1) window.location.reload();
                this.requests = [];

                // Use mergeAll to merge the array of observables into a single observable
                // return forkJoin(observables).pipe(mergeAll());
                return forkJoin(observables).pipe(mergeAll());
              }),
              catchError(() => {
                localStorage.clear();
                sessionStorage.clear();

                setTimeout(() => {
                  this.router.navigate(['/']).then();
                }, 800);

                return throwError(null);
              })
            );
          }
        }
        return throwError(error.error.statusCode == 401 ? null : error);
      })
    );
  }

  addTokenHeader(request: HttpRequest<any>, token: any) {
    return request.clone({headers: request.headers.set('Authorization', `Bearer ${token}`).set('sub-domain', localStorage.getItem('domain') || '').set('X-EMP-ID',localStorage.getItem('empId')||'')});
  }
}
