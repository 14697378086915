'use client';

var classes = {
  "root": "m_5ae2e3c",
  "barsLoader": "m_7a2bd4cd",
  "bar": "m_870bb79",
  "bars-loader-animation": "m_5d2b3b9d",
  "dotsLoader": "m_4e3f22d7",
  "dot": "m_870c4af",
  "loader-dots-animation": "m_aac34a1",
  "ovalLoader": "m_b34414df",
  "oval-loader-animation": "m_f8e89c4b"
};
export { classes as default };
