'use client';

function getInputOffsets(inputWrapperOrder, {
  hasDescription,
  hasError
}) {
  const inputIndex = inputWrapperOrder.findIndex(part => part === "input");
  const aboveInput = inputWrapperOrder.slice(0, inputIndex);
  const belowInput = inputWrapperOrder.slice(inputIndex + 1);
  const offsetTop = hasDescription && aboveInput.includes("description") || hasError && aboveInput.includes("error");
  const offsetBottom = hasDescription && belowInput.includes("description") || hasError && belowInput.includes("error");
  return {
    offsetBottom,
    offsetTop
  };
}
export { getInputOffsets };
