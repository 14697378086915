import React from "react";
import { createReactInlineContentSpec } from "@blocknote/react";

// The Mention inline content.
export const Mention = createReactInlineContentSpec(
  {
    type: "mention",
    propSchema: {
      user: {
        default: "Unknown",
      },
    },
    content: "none",
  },
  {
    render: (props) => (
      <data>
        {props.inlineContent.props.user}
      </data>
    ),
  }
);
