import React from "react";
import {
  DefaultReactSuggestionItem,
  getDefaultReactSlashMenuItems,
  SuggestionMenuController,
  useCreateBlockNote,
} from "@blocknote/react";
import { BlockNoteView, darkDefaultTheme, lightDefaultTheme, Theme } from "@blocknote/mantine";
import {
  BlockNoteSchema,
  defaultInlineContentSpecs,
  filterSuggestionItems,
  locales,
} from "@blocknote/core";
import { Mention } from "./mention";
import { EmployeeDto } from "../../data/objects/employee.dto";
import { localeFa } from "../locale.fa";

export const BlockNoteEditor = (props) => {
  const lang = props.container.lang;
  const theme = props.container.theme;
  const schema = BlockNoteSchema.create({
    inlineContentSpecs: {
      ...defaultInlineContentSpecs,
      mention: Mention,
    },
  });
  const getMentionMenuItems = (
    editor: typeof schema.BlockNoteEditor,
  ): DefaultReactSuggestionItem[] => {
    return props.container.users.map((user: EmployeeDto) => ({
      title: `${user.FullName.firstName} ${user.FullName.lastName}`,
      onItemClick: () => {
        editor.insertInlineContent([
          {
            type: "mention",
            props: {
              user: `${user.FullName.firstName} ${user.FullName.lastName}`,
            },
          },
          " ", // add a space after the mention
        ]);
      },
      icon: user.Icon ? (
        <img style={{ height: "24px", width: "24px" }} src={user.Icon || ""} />
      ) : (
        <span>{user.FullName.firstName.substring(0, 1)}</span>
      ),
    }));
  };
  const editor = useCreateBlockNote({
    schema,
    dictionary: lang == "fa" ? localeFa : locales.en,
    initialContent: props.container.content,
  });

  const handleEditorChange = () => {
    const jsonData = editor.document;
    window.dispatchEvent(new CustomEvent("editorChanged", { detail: jsonData }));
  };

  const light = {
    ...lightDefaultTheme,
    fontFamily: "Poppins, Vazirmatn",
    colors: {
      editor: { background: "#12394c06", text: "#0e2e3d" },
      menu: { background: "#fcfcfcee" },
      border: "#e7ebed",
      hovered: { background: "#a0b0b712" },
    },
  } satisfies Theme;
  const dark = {
    ...darkDefaultTheme,
    fontFamily: "Poppins, Vazirmatn",
    colors: {
      editor: { background: "#a0b0b706",text:"#e7ebed" },
      menu: { background: "#0b222eee" },
      border: "#103344",
      hovered: { background: "#a0b0b712" },
    },
  } satisfies Theme;
  const customTheme = theme == "light" ? light : dark;

  return (
    <BlockNoteView
      editor={editor}
      editable={props.container.editable}
      onChange={handleEditorChange}
      theme={customTheme}
    >
      <SuggestionMenuController
        triggerCharacter={"@"}
        getItems={async (query) => filterSuggestionItems(getMentionMenuItems(editor), query)}
      />

      <SuggestionMenuController
        triggerCharacter={"/"}
        getItems={async (query) =>
          filterSuggestionItems([...getDefaultReactSlashMenuItems(editor)], query)
        }
      />
    </BlockNoteView>
  );
};
