'use client';

function getSelectorClassName({
  selector,
  classes,
  unstyled
}) {
  return unstyled ? void 0 : classes[selector];
}
export { getSelectorClassName };
