'use client';

import { useState, useRef, useEffect } from 'react';
function attachMediaListener(query, callback) {
  try {
    query.addEventListener("change", callback);
    return () => query.removeEventListener("change", callback);
  } catch (e) {
    query.addListener(callback);
    return () => query.removeListener(callback);
  }
}
function getInitialValue(query, initialValue) {
  if (typeof initialValue === "boolean") {
    return initialValue;
  }
  if (typeof window !== "undefined" && "matchMedia" in window) {
    return window.matchMedia(query).matches;
  }
  return false;
}
function useMediaQuery(query, initialValue, {
  getInitialValueInEffect
} = {
  getInitialValueInEffect: true
}) {
  const [matches, setMatches] = useState(getInitialValueInEffect ? initialValue : getInitialValue(query));
  const queryRef = useRef();
  useEffect(() => {
    if ("matchMedia" in window) {
      queryRef.current = window.matchMedia(query);
      setMatches(queryRef.current.matches);
      return attachMediaListener(queryRef.current, event => setMatches(event.matches));
    }
    return void 0;
  }, [query]);
  return matches;
}
export { useMediaQuery };
