import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationRout } from './share/routes/authentication-rout';
import { AuthenticationComponent } from './layout/authentication/authentication.component';
import { LoginGuard } from './share/services/guards/login.guard';
import { NotFoundComponent } from './share/components/error/not-found/not-found.component';
import { PanelComponent as Panel } from './panel/panel.component';

const routes: Routes = [
  { path: '', redirectTo: 'auth/sign-in', pathMatch: 'full' },
  { path: '', component: AuthenticationComponent, children: AuthenticationRout },
  // {path: '', component: PanelComponent, children: PanelRout, canActivate: [LoginGuard]},
  {
    path: 'error',
    loadChildren: () => import('./share/components/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./share/components/payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'form',
    loadChildren: () => import('./formbuilder/formbuilder.module').then((m) => m.FormbuilderModule),
  },
  {
    path: 'portal',
    loadChildren: () => import('./portal/portal.module').then((m) => m.PortalModule),
  },
  {
    path: 'panel',
    component: Panel,
    loadChildren: () => import('./panel/panel.module').then((m) => m.PanelModule),
    canActivate: [LoginGuard],
  },
  // { path: 'test', component: FunctionPickerComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
