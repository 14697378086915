'use client';

function resolveStyles({
  theme,
  styles,
  props,
  stylesCtx
}) {
  const arrayStyles = Array.isArray(styles) ? styles : [styles];
  return arrayStyles.reduce((acc, style) => {
    if (typeof style === "function") {
      return {
        ...acc,
        ...style(theme, props, stylesCtx)
      };
    }
    return {
      ...acc,
      ...style
    };
  }, {});
}
export { resolveStyles };
