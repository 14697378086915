'use client';

import cx from 'clsx';
const EMPTY_CLASS_NAMES = {};
function mergeClassNames(objects) {
  const merged = {};
  objects.forEach(obj => {
    Object.entries(obj).forEach(([key, value]) => {
      if (merged[key]) {
        merged[key] = cx(merged[key], value);
      } else {
        merged[key] = value;
      }
    });
  });
  return merged;
}
function resolveClassNames({
  theme,
  classNames,
  props,
  stylesCtx
}) {
  const arrayClassNames = Array.isArray(classNames) ? classNames : [classNames];
  const resolvedClassNames = arrayClassNames.map(item => typeof item === "function" ? item(theme, props, stylesCtx) : item || EMPTY_CLASS_NAMES);
  return mergeClassNames(resolvedClassNames);
}
export { resolveClassNames };
