'use client';

import { mergeVars } from './merge-vars.mjs';
function resolveVars({
  vars,
  varsResolver,
  theme,
  props,
  stylesCtx,
  selector,
  themeName,
  headless
}) {
  return mergeVars([headless ? {} : varsResolver?.(theme, props, stylesCtx), ...themeName.map(name => theme.components?.[name]?.vars?.(theme, props, stylesCtx)), vars?.(theme, props, stylesCtx)])?.[selector];
}
export { resolveVars };
