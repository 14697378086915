'use client';

import { jsx } from 'react/jsx-runtime';
import 'react';
import { getSpacing } from '../../core/utils/get-size/get-size.mjs';
import '@mantine/hooks';
import { createVarsResolver } from '../../core/styles-api/create-vars-resolver/create-vars-resolver.mjs';
import 'clsx';
import '../../core/MantineProvider/Mantine.context.mjs';
import '../../core/MantineProvider/default-theme.mjs';
import '../../core/MantineProvider/MantineProvider.mjs';
import '../../core/MantineProvider/MantineThemeProvider/MantineThemeProvider.mjs';
import { useProps } from '../../core/MantineProvider/use-props/use-props.mjs';
import '../../core/MantineProvider/MantineCssVariables/MantineCssVariables.mjs';
import { useStyles } from '../../core/styles-api/use-styles/use-styles.mjs';
import { Box } from '../../core/Box/Box.mjs';
import { factory } from '../../core/factory/factory.mjs';
import '../../core/DirectionProvider/DirectionProvider.mjs';
import { filterFalsyChildren } from './filter-falsy-children/filter-falsy-children.mjs';
import classes from './Group.module.css.mjs';
const defaultProps = {
  preventGrowOverflow: true,
  gap: "md",
  align: "center",
  justify: "flex-start",
  wrap: "wrap"
};
const varsResolver = createVarsResolver((_, {
  grow,
  preventGrowOverflow,
  gap,
  align,
  justify,
  wrap
}, {
  childWidth
}) => ({
  root: {
    "--group-child-width": grow && preventGrowOverflow ? childWidth : void 0,
    "--group-gap": getSpacing(gap),
    "--group-align": align,
    "--group-justify": justify,
    "--group-wrap": wrap
  }
}));
const Group = factory((_props, ref) => {
  const props = useProps("Group", defaultProps, _props);
  const {
    classNames,
    className,
    style,
    styles,
    unstyled,
    children,
    gap,
    align,
    justify,
    wrap,
    grow,
    preventGrowOverflow,
    vars,
    variant,
    __size,
    mod,
    ...others
  } = props;
  const filteredChildren = filterFalsyChildren(children);
  const childrenCount = filteredChildren.length;
  const resolvedGap = getSpacing(gap ?? "md");
  const childWidth = `calc(${100 / childrenCount}% - (${resolvedGap} - ${resolvedGap} / ${childrenCount}))`;
  const stylesCtx = {
    childWidth
  };
  const getStyles = useStyles({
    name: "Group",
    props,
    stylesCtx,
    className,
    style,
    classes,
    classNames,
    styles,
    unstyled,
    vars,
    varsResolver
  });
  return /* @__PURE__ */jsx(Box, {
    ...getStyles("root"),
    ref,
    variant,
    mod: [{
      grow
    }, mod],
    size: __size,
    ...others,
    children: filteredChildren
  });
});
Group.classes = classes;
Group.displayName = "@mantine/core/Group";
export { Group };
