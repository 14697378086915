/**
 * Working with value pairs.
 *
 * @module pair
 */

/**
 * @template L,R
 */
export class Pair {
  /**
   * @param {L} left
   * @param {R} right
   */
  constructor(left, right) {
    this.left = left;
    this.right = right;
  }
}

/**
 * @template L,R
 * @param {L} left
 * @param {R} right
 * @return {Pair<L,R>}
 */
export const create = (left, right) => new Pair(left, right);

/**
 * @template L,R
 * @param {R} right
 * @param {L} left
 * @return {Pair<L,R>}
 */
export const createReversed = (right, left) => new Pair(left, right);

/**
 * @template L,R
 * @param {Array<Pair<L,R>>} arr
 * @param {function(L, R):any} f
 */
export const forEach = (arr, f) => arr.forEach(p => f(p.left, p.right));

/**
 * @template L,R,X
 * @param {Array<Pair<L,R>>} arr
 * @param {function(L, R):X} f
 * @return {Array<X>}
 */
export const map = (arr, f) => arr.map(p => f(p.left, p.right));